<template>
  <v-app-bar class="pt-4 mx-2 pl-4 pr-6 grey-background" flat :height="dense ? '11' : '35'" app fixed style=" top: 56px; z-index: 5; transition: none !important" :style="`${scroll > 80 ? 'box-shadow: 2px 4px 6px -6px rgba(69, 90, 100, 0.15) !important;' : ''};`">
    <v-toolbar-title class="grey-700--text">
      <span class="d-block subtitle-1 font-weight-semibold" :class="dense ? 'pt-7' : 'pt-1'">
        {{breadcrumbs.main}}
        <v-icon class="mx-1" v-if="((breadcrumbs || {}).children || []).length" color="grey-700" size="20">mdi-chevron-right</v-icon>
        <span v-for="(b, i) in breadcrumbs.children" :key="i">
          <router-link v-if="b.route" :to="typeof b.route === 'string' ? { name: b.route, query, params: currentParams } : b.route" class="text-decoration-none grey-700--text">{{ b.text }}</router-link>
          <template v-else>{{b.text === 'SII' ? $t('generals.SII') : b.text}}</template>
          <template v-if="b.text1">
            <v-icon class="mx-1" color="grey-700" size="20">mdi-chevron-right</v-icon>
            <v-btn v-if="b.route1" :to="b.route1 === 'string' ? { name: b.route1, query, params: currentParams } : b.route1" text style="justify-content: start; padding: 0 !important">
              <span :class="`d-inline-block text-truncate subtitle-1 grey-700--text font-weight-semibold`" :style="`max-width: ${$vuetify.breakpoint.width < 1250 ? '99%' : 'auto'}; position: relative`">{{b.text1 }}</span>
            </v-btn>
            <template v-else>{{ b.text1 }} </template>
          </template>
          <template v-if="b.text2">
            <v-icon class="mx-1" color="grey-700" size="20">mdi-chevron-right</v-icon>
            <router-link v-if="b.route2" :to="b.route2" class="text-decoration-none grey-700--text">{{ b.text2 }}</router-link>
            <template v-else>{{ b.text2 }}</template>
          </template>
        </span>
        <v-icon class="mx-1" v-if="Object.keys(breadcrumbsItemDinamic).length" color="grey-700" size="22">mdi-chevron-right</v-icon>
        <v-avatar v-if="breadcrumbsItemDinamic.loading" tile class="placeholder rounded-pill" height="15" width="300" />
        <template v-else>{{ breadcrumbsItemDinamic.text }}</template>

        <v-tooltip v-if="tooltip.length" color="white-0" right max-width="250px" transition="scale-transition" z-index="1000">
          <template v-slot:activator="{ on }">
            <span class="grey-700--text body-1" v-on="on">
              <v-icon class="ml-2" small color="grey-700">mdi-information</v-icon>
            </span>
          </template>
          <span class="d-block px-3 py-2">{{ tooltip  }}</span>
        </v-tooltip>
      </span>
      <span v-if="inf" class="d-block subtitle-2 mt-3">{{ inf }}</span>
    </v-toolbar-title>
    <v-spacer />
    <v-toolbar-items class="pr-0 mr-n2 pb-0">
      <v-row align="center" justify="end" no-gutters>
        <slot name="main" />
      </v-row>
    </v-toolbar-items>
    <template v-slot:extension>
      <div class="col-12 pa-0 mt-n3 mx-6 d-flex justify-end none">
        <slot name="custom-tab" />
        <template v-if="!hiddenTab">
          <!-- by status filter -->
          <v-tabs v-model="current" class="mt-n3 pt-8 pl-4 pr-5 grey-background" :height="35" style="position: absolute; z-index: 0 !important">
            <v-tab :ripple="false">
              <v-icon left size="18">{{ iconAllTab }}</v-icon>Todos
            </v-tab>
            <v-tab v-for="({ key }, index) in list" :key="index" :ripple="false">
              <v-icon left size="18">{{status[index]?.icon}}</v-icon>{{key}}
            </v-tab>
          </v-tabs>
          <!-- end by status filter -->
          <div class="d-flex mt-1 pr-5" style="position: absolute;">
            <slot name="grey-700" />
          </div>
        </template>
      </div>
    </template>
  </v-app-bar>
</template>
<script>
import { mapState } from 'vuex'
export default {
  props: {
    scroll: {
      type: Number,
      default: 0
    },
    hiddenTab: {
      type: Boolean,
      default: true
    },
    dense: {
      type: Boolean,
      default: false
    },
    addPadding: {
      type: Boolean,
      default: false
    },
    showTitle: {
      type: Boolean,
      default: false
    },
    list: {
      type: Array,
      default: () => ([])
    },
    filter: {
      default: Number
    },
    status: {
      type: Array,
      default: () => ([])
    },
    title: {
      type: String,
      default: ''
    },
    breadcrumbs: {
      type: Object,
      default: () => ({})
    },
    tooltip: {
      type: String,
      default: ''
    },
    inf: {
      type: String,
      default: ''
    },
    breadcrumbsItemDinamic: {
      type: Object,
      default: () => ({})
    },
    iconAllTab: {
      type: String,
      default: 'mdi-ballot'
    }
  },
  data: () => ({
    current: 0
  }),
  watch: {
    current (val) {
      this.$emit('update:filter', val)
    }
  },
  created () {
    this.current = this.filter
  },
  computed: {
    ...mapState({
      query: state => state.base.query
    }),
    currentParams () {
      let params = JSON.parse(JSON.stringify(this.$route.params))
      delete params.id
      return params
    }
  }
}
</script>